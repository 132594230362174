@use './scss/colors';

// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;600&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;600&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-sizing: border-box !important;
  transition: all 0.3s ease-out;
}

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Work Sans', sans-serif;
  background-color: colors.$backgroundDark;
}


::-webkit-scrollbar{
  width: 20px;
}

::-webkit-scrollbar-track{
  background: grey;
}

::-webkit-scrollbar-thumb{
  background-image: yellow;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover{
  background-image:  rgb(231, 96, 12);
}