.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: #4285f4; /*#0000ff;*/ /*#44ce6f;*/
  -webkit-animation: backgroundcolorchange 20s infinite alternate;
  animation: backgroundcolorchange 20s infinite alternate; 

  .spinner {
    width: 60px;
    height: 60px;
    background-color: #FBB03B;
    margin: 0 auto;
    -webkit-animation: sk-rotateplane 1.2s infinite alternate;
    animation: sk-rotateplane 1.2s infinite alternate;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.4);
    top: 50%;
    margin-top: -75px;
  }
}

@keyframes backgroundcolorchange {
  0% {
    background: #000000;
  }

  10% {
    background: #1C1C1C;
  }

  20% {
    background: #222222;
  }

  30% {
    background: #BABABA;
  }

  40% {
    background: #EFEFEF;
  }

  50% {
    background: #ffffff;
  }

  60% {
    background: #EFEFEF;
  }

  70% {
    background: #BABABA;
  }

  80% {
    background: #222222;
  }

  90% {
    background: #1C1C1C;
  }

  100% {
    background: #000000;
  }
}

@keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
